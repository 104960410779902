import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function AuthorList({ items }) {
  return (
    <div>
      <ul className="list-none m-0 mx-auto">
        {items.map(({ author, index }) => {
          const authorName = author && author.name;
          return (
            <li key={index} className="m-0 flex items-center justify-center">
              <div
                className="rounded-full overflow-hidden"
                style={{ width: "60px", height: "60px" }}
              >
                {author && author.image && author.image.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(author.image))
                      .width(60)
                      .height(60)
                      .fit("crop")
                      .url()}
                    alt=""
                  />
                )}
              </div>
              <div className="text-center text-md md:text-lg text-body ml-6">
                {authorName || <em>Missing name</em>}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default AuthorList;
