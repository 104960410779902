import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import BlogPost from "../components/blog-post";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      address
      title
      facebook
      email
      phone
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
    nav: allSanityNavigationMenu(filter: { title: { eq: "Main Menu" } }) {
      nodes {
        items {
          kind
          link
          route
          title
          landingPageRoute {
            slug {
              current
              _type
            }
            _id
            _type
          }
        }
      }
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { data, errors } = props;

  const post = data && data.post;

  const site = (data || {}).site;

  const menuItems = data.nav.nodes[0].items;

  const address = site.address;
  const facebook = site.facebook;
  const email = site.email;
  const phone = site.phone;

  return (
    <Layout
      navMenuItems={menuItems}
      siteTitle={site.title}
      textWhite={true}
      address={address}
      facebook={facebook}
      email={email}
      phone={phone}
    >
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || "Untitled"}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
          bodyAttr={{
            class: "leading-normal tracking-normal text-white bg-tertiary",
          }}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && <BlogPost {...post} />}
    </Layout>
  );
};

export default BlogPostTemplate;
