import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import PortableText from "./portableText";
import AuthorList from "./author-list";

function BlogPost(props) {
  const { _rawBody, authors, title, publishedAt } = props;
  return (
    <article>
      <div className="container mx-auto py-12 px-4 sm:px-8 md:px-8 w-full max-w-5xl">
        <h1 className="text-5xl text-primary text-center leading-tight mb-4">{title}</h1>
        {publishedAt && (
          <div className="text-center text-md md:text-lg text-body border-b border-gray pb-6 mb-6">
            {differenceInDays(new Date(publishedAt), new Date()) > 3
              ? distanceInWords(new Date(publishedAt), new Date())
              : format(new Date(publishedAt), "MMMM Do, YYYY")}
          </div>
        )}
        {_rawBody && (
          <div className="leading-normal text-md md:text-lg text-body mb-6 font-light border-b border-gray pb-6">
            <PortableText blocks={_rawBody} />
          </div>
        )}
        {authors && <AuthorList items={authors} title="Authors" />}
      </div>
    </article>
  );
}

export default BlogPost;
